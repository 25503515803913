import React from 'react';
import Offers from './componates/offers/Offers';

function Service(props) {
    return (
        <div>
            <Offers />
        </div>
    );
}

export default Service;